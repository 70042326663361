import {
    MENU_GET,
    MENU_SUCCESS,
    MENU_ERROR
} from "./actionTypes";


export const getMenu = () => {
    return {
        type: MENU_GET
      }
}; 

  export const getMenuSuccess = arrayMenu => {
    return {
      type: MENU_SUCCESS,
      payload: arrayMenu
    }
  };
  

  export const getMenuError = error => {
    return {
      type: MENU_ERROR,
      payload: error
    }
  };