import {
    ROUTES_GET,
    ROUTES_SUCCESS,
    ROUTES_ERROR
} from "./actionTypes";


export const getRoutes = () => {
    return {
        type: ROUTES_GET
      }
}; 

  export const getRoutesSuccess = arrayRoutes => {
    return {
      type: ROUTES_SUCCESS,
      payload: arrayRoutes
    }
  };
  

  export const getRoutesError = error => {
    return {
      type: ROUTES_ERROR,
      payload: error
    }
  };