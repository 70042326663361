import {
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION
  } from "./actionTypes";
  

  export const showNotification = props => {
    return {
        type: SHOW_NOTIFICATION,
        payload: props
      }
  }; 
  
  export const hideNotification = () => {
    return {
        type: HIDE_NOTIFICATION,
      }
  }; 
  
  