import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { getToken, clearToken } from '@iso/lib/helpers/utility';
import { MENU_GET, MENU_SUCCESS, MENU_ERROR } from "./actionTypes";
import { getMenuSuccess, getMenuError } from "./actions";

import { getMenu } from '../../library/http/backendHelpers';

const history = createBrowserHistory();


function* getMenuRequest(){
    try {
        const response = yield call(getMenu);
        yield put(getMenuSuccess(response.data))
      } catch (error) {
        alert(error)
        yield put(getMenuError(error));
      }
}

function* menuSaga() {
    yield takeEvery(MENU_GET, getMenuRequest);
  }

export default menuSaga
