import {
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION
  } from "./actionTypes";


const initialState = {
      type : "",
      description : "",
      title : "",
  };

const notification =  (state = initialState, action) => {
    switch (action.type) {
        case SHOW_NOTIFICATION:
        return {
          type: action.payload.type,
          description: action.payload.description,
          title: action.payload.title,
        };
      case HIDE_NOTIFICATION:
        return {
          type: "",
          description: "",
          title: "",
      };
      default:
        return state;
    }
  }
  export default  notification
