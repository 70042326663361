import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { ROUTES_GET } from "./actionTypes";
import { getRoutesSuccess, getRoutesError } from "./actions";

import { getRoutes } from '../../library/http/backendHelpers';

const history = createBrowserHistory();


function* getRoutesRequest(){
    try {
        const response = yield call(getRoutes);
        yield put(getRoutesSuccess(response.data))
      } catch (error) {
        yield put(getRoutesError(error));
      }
}

function* routesSaga() {
    yield takeEvery(ROUTES_GET, getRoutesRequest);
  }

export default routesSaga
