import React from 'react';
import ReactDOM from 'react-dom';

import { Button, notification, Space } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { hideNotification } from '@iso/redux/notification/actions';


const openNotificationWithIcon = (type, title, description) => {
    notification[type]({
        message: title,
        description: description,
        duration: 2
    });
};

const NotificationWindow = () => {

    const dispatch = useDispatch();

    let type = useSelector((state) => state.notification.type);
    let description = useSelector((state) => state.notification.description);
    let title = useSelector((state) => state.notification.title);

    if (type !== "" && description !== "" && title !== "") {
        dispatch(hideNotification())
        return (
            openNotificationWithIcon(type, title, description)
        )
    } else {
        return null
    }
};

export default NotificationWindow;
