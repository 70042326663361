import {
  SHOW_MODAL_MAIN,
  HIDE_MODAL_MAIN
} from "./actionTypes";

const initialState = {
  modalVisibility: false,
  modalType: '',
  modalProps: {},
  modalBodyProps: {}
};

const modalMain =  (state = initialState, action) => {


  switch (action.type) {
    
    case SHOW_MODAL_MAIN:
     
      return {
        modalVisibility: true,
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
        modalBodyProps: action.payload.modalBodyProps,
        
      };
    case HIDE_MODAL_MAIN:
      return {
      modalVisibility: false,
      modalType: null,
      modalProps: null,
      modalBodyProps: null,
    };
    default:
      return state;
  }
}

export default  modalMain