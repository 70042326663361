import {
  SHOW_MODAL_MAIN,
  HIDE_MODAL_MAIN
} from "./actionTypes";

export const openModalMain = props => {
  return {
      type: SHOW_MODAL_MAIN,
      payload: props
    }
}; 

export const closeModalMain = () => {
  return {
      type: HIDE_MODAL_MAIN,
    }
}; 

